import { render, staticRenderFns } from "./NoDataFound.vue?vue&type=template&id=622a5746&scoped=true&"
var script = {}
import style0 from "./NoDataFound.vue?vue&type=style&index=0&id=622a5746&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "622a5746",
  null
  
)

export default component.exports